import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAreas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/area', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchArea(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/area/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateArea(ctx, { areaData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/area/${id}`, areaData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteArea(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/area/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addArea(ctx, areaData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/area', areaData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
