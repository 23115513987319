import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useAreaList() {
  // Use toast
  const toast = useToast();

  const refAreaListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: 'الرقم التسلسلي',
      formatter: title,
      sortable: true,
    },
    {
      key: 'name',
      label: 'إسم المساحة',
      formatter: title,
      sortable: true,
    },
    { key: 'actions', label: 'الإجراءات' },
  ];
  const perPage = ref(10);
  const totalArea = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const dataMeta = computed(() => {
    const localItemsCount = refAreaListTable.value ? refAreaListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalArea.value,
    };
  });

  const refetchData = () => {
    refAreaListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchAreas = (ctx, callback) => {
    store
      .dispatch('area/fetchAreas', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        console.log(response.data);
        const { data: areas, total } = response.data;

        callback(areas);
        totalArea.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'حدث خطأ عند جلب القائمة',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchAreas,
    tableColumns,
    perPage,
    currentPage,
    totalArea,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAreaListTable,
    refetchData,

    // Extra Filters
  };
}
